<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商名称">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="额度名称">
                <a-input v-model="searchData.name" placeholder="请输入额度名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="大区事务所">
                <a-cascader
                  :options="dealerList"
                  v-model="regionalOffice"
                  change-on-select
                  placeholder="请选择大区/事务所"
                  @change="onChange"
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-item label="额度类型">
                <a-select placeholder="请选择" v-model="searchData.limitType" allowClear>
                  <a-select-option :value="1">全部商品</a-select-option>
                  <a-select-option :value="2">商品分类</a-select-option>
                  <a-select-option :value="3">指定商品</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-item label="审核状态">
                <a-select placeholder="请选择" v-model="searchData.authStatus" allowClear>
                  <a-select-option :value="0">待处理</a-select-option>
                  <a-select-option :value="1">待审核</a-select-option>
                  <a-select-option :value="2">审核通过</a-select-option>
                  <a-select-option :value="3">审核拒绝</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-item label="额度状态">
                <a-select placeholder="请选择" v-model="searchData.limitStatus" allowClear>
                  <a-select-option :value="0">未开始</a-select-option>
                  <a-select-option :value="1">使用中</a-select-option>
                  <a-select-option :value="2">即将过期</a-select-option>
                  <a-select-option :value="3">已逾期</a-select-option>
                  <a-select-option :value="4">已过期</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="有效时间">
                <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
              </a-form-model-item>
            </a-col>

            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isAdd" type="primary" @click="hanlderTest('add')" icon="plus">新增</a-button>
          <a-button v-if="isEdit" type="primary" @click="toHandler('edit')" icon="edit">编辑</a-button>
          <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
          <a-button v-if="isDel" type="primary" @click="toHandler('del')">删除</a-button>
          <a-button v-if="isRecord" type="primary" @click="hanlderTest('check')" icon="copy">使用流水</a-button>
          <a-button v-if="isRepaymentRecord" type="primary" @click="hanlderTest('checkRepayment')" icon="copy">还款记录</a-button>
          <a-button v-if="isLog" type="primary" @click="hanlderTest('checkLog')" icon="file">操作日志</a-button>
          <a-button v-if="isRepayment" type="primary" @click="hanlderTest('still')" icon="redo">还款</a-button>
          <a-button v-if="isRollback" type="primary" @click="handleAuthagain">返审</a-button>
          <a-button v-if="isDel && selectedRows.length == 1 && selectedRows[0].authStatus == 2 && selectedRows[0].limitStatus == 4" type="primary" @click="delayVisible = true;rowDetail = selectedRows[0]">延期</a-button>
          <a-button v-if="isSubmit && selectedRows.length > 0 && selectedRows[0].authStatus == 0" type="primary" @click="batchSubmitAudit">提交审核</a-button>
          <a-button v-if="isAudit && selectedRows.length == 1 && selectedRows[0].authStatus == 1 && selectedRows[0].taskId != null" type="primary" @click="authReason = '';closeConfirmIsView = 1;authVisible = true;authAndCloseRowId = selectedRows[0].taskId">审核</a-button>
          <a-button v-if="isAuditList && selectedRows.length > 1 && selectedRows[0].authStatus == 1 && selectedRows[0].taskId != null" type="primary" @click="toHandlerAll">批量审核</a-button>
          <!--          <downLoad-->
          <!--            method="get"-->
          <!--            api="/api/order/order/orderInfo/exportSingleOrderInfo"-->
          <!--            :params="{ id: 11 }"-->
          <!--            name="导出.xlsx"-->
          <!--            >导出</downLoad-->
          <!--          >-->
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="setKey"
          :columns="columns"
          :data-source="tableData"
          bordered
          :pagination="page"
          @change="changeTable"
          :customRow="changeTableRow"
          ref="table"
        >
          <span slot="limitType" slot-scope="text">
            <span v-if="text == 1"><a-tag color="green">全部商品</a-tag></span>
            <span v-if="text == 2"><a-tag color="cyan">商品分类</a-tag></span>
            <span v-if="text == 3"><a-tag color="blue">指定商品</a-tag></span>
          </span>
          <span slot="limitStatus" slot-scope="text">
            <a-tag color="#808080" v-if="text == 0">未开始</a-tag>
            <a-tag color="#e6a23c" v-if="text == 1">使用中</a-tag>
            <a-tag color="#ffc000" v-if="text == 2">即将过期</a-tag>
            <a-tag color="red" v-if="text == 3">已逾期</a-tag>
            <a-tag color="#333333" v-if="text == 4">已过期</a-tag>
          </span>
          <span slot="authStatus" slot-scope="text">
            <a-tag v-if="text == 0" color="#333333">待处理</a-tag>
            <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
            <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
            <a-tag v-if="text == 3" color="#67c23a">审核拒绝</a-tag>
          </span>
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="limitCode" slot-scope="text, row">
            <span @click.stop="">
              <a href="#" @click="toHandler('checkLSH', row)">{{ text }}</a>
            </span>
          </span>
          <span slot="dealerName" slot-scope="text, row">
            <span @click.stop="">
              <a href="#" @click="toHandler('checkJXS', row)">{{ text }}</a>
            </span>
          </span>
          <span slot="validityStart" slot-scope="text, row"> {{ row.validityStart }} ~ {{ row.validityEnd }} </span>
          <span slot="flagEnable" slot-scope="text, record">
            <span @click.stop="">
              <a-switch
                v-model="record.flagEnable"
                @change="onChangeStatus(record)"
                :disabled="record.limitStatus == 4"
              />
            </span>
          </span>
        </a-table>
        <!-- 信用额度 -->
        <FinanceCreditModal ref="FinanceCreditModal" @reload="getData"></FinanceCreditModal>
        <SingleRepaymentModal ref="SingleRepaymentModal" @reload="getData"></SingleRepaymentModal>
        <UsedDetailed ref="UsedDetailed"> </UsedDetailed>
        <RepaymentDetailed ref="RepaymentDetailed"> </RepaymentDetailed>
        <OperateLog ref="OperateLog"></OperateLog>

        <a-modal
          title="延期"
          width="30%"
          :visible="delayVisible"
          :confirm-loading="authConfirmLoading"
          :footer="null"
          @cancel="delayVisible = false"
          :maskClosable="false"
        >
          <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
            <a-form-model-item label="有效日期" prop="validityStart">
              <RangePicker
                :startTime.sync="rowDetail.validityStart"
                :endTime.sync="rowDetail.validityEnd"
                :disabled-date="disabledDate"
              ></RangePicker>
            </a-form-model-item>
            <a-form-model-item label="备注">
              <a-textarea v-model="rowDetail.delayRemark"></a-textarea>
            </a-form-model-item>
          </a-form-model>
          <div class="footer-bts">
            <a-button type="default" @click="delayVisible = false">取消</a-button>
            <a-button type="primary" @click="handleDelay">确认</a-button>
          </div>
        </a-modal>

        <a-modal
          :title="closeConfirmIsView == 1 ? '审核' : closeConfirmIsView == 2 ? '驳回' : '结束'"
          width="30%"
          :visible="authVisible"
          :confirm-loading="authConfirmLoading"
          :footer="null"
          @cancel="authVisible = false"
          :maskClosable="false"
        >
          <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
            <a-form-model-item label="原因">
              <a-textarea v-model="authReason"></a-textarea>
            </a-form-model-item>
          </a-form-model>
          <div class="footer-bts">
            <a-button type="default" @click="authVisible = false">取消</a-button>
            <a-button type="danger" @click="handleAuth(authAndCloseRowId, 2, false)" v-if="closeConfirmIsView == 1"
              >拒绝</a-button
            >
            <a-button type="primary" @click="handleAuth(authAndCloseRowId, 2, true)" v-if="closeConfirmIsView == 1"
              >通过</a-button
            >
          </div>
        </a-modal>

        <a-modal
          v-model="visibleAll"
          title="批量审核"
          @ok="handleOkAll"
          @cancel="handleCanceAll"
          :maskClosable="false"
          width="40%"
        >
          <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
            <a-form-model-item label="是否通过">
              <a-radio-group v-model="statusAll">
                <a-radio :value="2"> 通过 </a-radio>
                <a-radio :value="3"> 拒绝 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="备注">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </a-form-model>
        </a-modal>

        <a-modal v-model="visibleLimit" title="返审" :footer="null" :maskClosable="false" width="50%">
          <a-table
            :rowKey="(record) => record.id"
            :columns="columnsLimit"
            :data-source="tableDataLimit"
            bordered
            :pagination="false"
            :rowClassName="rowClassName"
          >
            <span slot="orderStatus" slot-scope="text, row">
              <span v-if="row.orderStatus == 0">待审核</span>
              <span v-if="row.orderStatus == 4">已审核待发货</span>
              <span v-if="row.orderStatus == 5">部分发货</span>
              <span v-if="row.orderStatus == 9">草稿</span>
            </span>
          </a-table>
        </a-modal>
        <!-- 经销商信息 -->
        <CustomerInfoCheckModal ref="CustomerInfoCheckModal"></CustomerInfoCheckModal>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { columns } from './thead/colums'
import SingleRepaymentModal from './components/SingleRepaymentModal.vue'
import UsedDetailed from './components/UsedDetailed'
import RepaymentDetailed from './components/RepaymentDetailed'
import OperateLog from './components/OperateLog'
import { delLimitInfo, downExcel, rollbackStatus, returnStatus, listLimitInfo } from './api/LimitInfoApi'
import { checkPermission } from '@/utils/permissions'
import { baseURL } from '@/utils/request'
import { auditTask, auditTaskBatch } from '@/api/activiti'
import { PAYMENT_TEMP } from '@/utils/downloadTempHref'
import CustomerInfoCheckModal from '../customer/components/CustomerInfoCheckModal.vue'
import moment from 'moment'
export default {
  name: 'limitInfo',
  components: {
    SingleRepaymentModal,
    FinanceCreditModal: () => import('./components/FinanceCreditModal'),
    UsedDetailed,
    OperateLog,
    CustomerInfoCheckModal,
    RepaymentDetailed,
  },
  data() {
    return {
      delayVisible: false,
      rowDetail: {},
      authReason: '',
      closeConfirmIsView: 1,
      authAndCloseRowId: 0,
      authConfirmLoading: false,
      authVisible: false,
      statusAll: 2,
      rejectReason: '',
      visibleAll: false,
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      loading: false,
      loadingAll: false,
      verifyRemark: '',
      dealerList: [],
      regionalOffice: [],
      dataList: [],
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/dealer/dealer/dealerPaymentFrom/excelReader',
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      downloadTempHref: PAYMENT_TEMP,
      spinning: false,
      limitList: [],
      tableDataLimit: [],
      columnsLimit: [
        {
          title: '订单号',
          dataIndex: 'orderCode',
          key: 'orderCode',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          key: 'orderStatus',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'orderStatus' },
        },
      ],
      visibleLimit: false,
      LimitName: '返审',
      isAdd: checkPermission('limit:info:add'),
      isEdit: checkPermission('limit:info:edit'),
      isCheck: checkPermission('limit:info:check'),
      isDel: checkPermission('limit:info:del'),
      isRecord: checkPermission('limit:info:record'),
      isRepaymentRecord: checkPermission('limit:info:repaymentRecord'),
      isLog: checkPermission('limit:info:log'),
      isRepayment: checkPermission('limit:info:repayment'),
      isRollback: checkPermission('limit:info:rollback'),
      isDelay: checkPermission('limit:info:delay'),
      isSubmit: checkPermission('limit:info:submit'),
      isAudit: checkPermission('limit:info:audit'),
      isAuditList: checkPermission('limit:info:auditList'),
      distributor_name: this.$route.params.dealerName,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },
  mounted() {
    if (this.distributor_name) {
      this.searchData.dealerName = this.distributor_name
    }
    this.getData()
  },
  methods: {
    setKey(record) {
      return record.id
    },
    hanlderTest(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.FinanceCreditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'still') {
        if (_this.selectedRows.length === 1) {
          const everyResult = this.selectedRows.every((item) => {
            return item.usedAmount > 0
          })
          if (everyResult) {
            _this.$refs.SingleRepaymentModal.setRowData(this.selectedRows[0])
            return
          } else {
            this.$message.warning('请选择已使用的额度')
          }
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.UsedDetailed.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'checkRepayment') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.RepaymentDetailed.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'checkLog') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.OperateLog.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
    },
    /**
     *
     *
     *
     *
     *
     */
    downLoadTemp() {
      window.open(this.downloadTempHref, '_blank')
    },
    startDownLoad() {
      this.spinning = true
    },
    downLoadDone() {
      this.spinning = false
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    /**
     * 批量提交审核
     */
    batchSubmitAudit() {
      var _this = this
      const everyResult = this.selectedRows.every((item) => {
        return item.authStatus === 0
      })
      if (everyResult) {
        this.dataList = this.selectedRows
        _this.$confirm({
          title: '提交审核',
          content: '确认要提交审核吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            _this.tableLoading = true
            const query = {
              ids: _this.dataList.map((item) => item.id),
            }
            _this.axios.post('/api/dealer/limit/limitInfo/batchSubmitAudit', query).then((res) => {
              if (res.code == 200) {
                _this.$message.success(res.message)
                _this.getData()
              } else {
                _this.$message.error(res.message)
                _this.getData()
              }
              _this.tableLoading = false
            })
          },
          onCancel() {},
        })
      } else {
        this.$message.warning('请选择未提交审核记录')
      }
    },

    // 返审
    handleAuthagain() {
      if (this.selectedRows.length === 1) {
        const everyResult = this.selectedRows.every((item) => {
          return item.authStatus == 2
        })
        if (everyResult) {
          this.axios.get(`/api/order/order/orderInfo/listByLimitId/${this.selectedRows[0].id}`).then((res) => {
            let limitList = res.body || []
            if (limitList.length === 0) {
              const _this = this
              _this.$confirm({
                title: '返审警告',
                content: '确认要返审此条记录吗?',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                  _this.tableLoading = true
                  _this.axios.post(`/api/dealer/limit/limitInfo/status/${_this.selectedRows[0].id}`).then((res) => {
                    if (res.code === 200) {
                      _this.$message.success(res.message)
                      _this.getData()
                    } else {
                      _this.$message.error(res.message)
                    }
                    _this.tableLoading = false
                  })
                },
                onCancel() {},
              })
            } else {
              this.visibleLimit = true
              this.limitName = '查看返审记录'
              this.tableDataLimit = limitList
            }
          })
          return
        } else {
          this.$message.warning('请选择已审核的额度')
        }
      } else {
        this.$message.warning('请选择一条记录')
      }
    },

    // 延期
    handleDelay() {
      this.tableLoading = true
      this.axios.post('/api/dealer/limit/limitInfo/delay', this.rowDetail).then((res) => {
        if (res.code == 200) {
          this.delayVisible = false
          this.rowDetail = {}
          this.$message.success(res.message)
          this.getData()
        } else {
          this.$message.error(res.message)
          this.getData()
        }
        this.tableLoading = false
      })
    },
    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.authStatus === 1 && item.taskId != null
      })
      if (everyResult) {
        this.visibleAll = true
        this.dataList = this.selectedRows
      } else {
        this.$message.warning('请选择相同待审核记录')
      }
    },
    handleOkAll() {
      const _this = this
      let result = _this.statusAll
      _this.tableLoading = true
      auditTaskBatch({
        taskIds: _this.dataList.map((item) => item.taskId),
        result: result,
        comment: _this.rejectReason,
      }).then((res) => {
        if (res.code == 200) {
          _this.$message.success('审核成功')
          _this.getData()
        } else {
          _this.$message.error(res.message)
        }
        _this.visibleAll = false
        _this.tableLoading = false
      })
    },
    handleCanceAll() {
      this.visibleAll = false
      this.statusAll = ''
      this.rejectReason = ''
    },

    //处理审核 flag 1:提交审核 2:政策审核 3:政策结束 4:驳回原因
    handleAuth(id, flag, authStatus) {
      console.log('id=' + id + '&flag=' + flag + '&authStatus=' + authStatus)
      const _this = this
      _this.tableLoading = true
      var params = 'limitId=' + id
      switch (flag) {
        case 2:
          let result = 2
          if (authStatus == false) {
            result = 3
          }
          const query = {
            taskId: id,
            result: result,
            comment: _this.authReason,
          }
          auditTask(query).then((res) => {
            if (res.code === 200) {
              _this.$message.success('审核成功')
              _this.getDataFilter()
            }
            _this.tableLoading = false
          })
          _this.authVisible = false
          _this.authReason = ''
          break
      }
    },
    downItemExcel() {
      const _this = this
      const params = _this.tableData
      downExcel(params)
        .then((res) => {
          console.log('进不来')
        })
        .catch((error) => {
          const blob = new Blob([error], {
            type: 'application/vnd.ms-excel',
          })
          const objectUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = objectUrl
          a.download = '收款单'
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
          window.URL.revokeObjectURL(blob)
        })
    },
    //启用禁用
    onChangeStatus(record) {
      this.axios
        .post(`/api/dealer/limit/limitInfo/enable/` + record.id)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.getData()
          } else {
            this.getData()
          }
        })
        .catch((err) => {
          this.getData()
        })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 获取表格数据
     */
    getData() {
      console.log('获取表格数据', this.searchData)
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listLimitInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },

    disabledDate(current) {
      return current && current == moment()
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },

    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      this.loadingAll = false
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    toHandler(name, row) {
      if (name === 'checkLSH') {
        if (this.$refs.FinanceCreditModal) {
          this.$refs.FinanceCreditModal.setRowData(row, 'check')
        }
        return false
      }
      if (name === 'checkJXS') {
        const item = {
          id: row.dealerId,
        }
        if (this.$refs.CustomerInfoCheckModal) {
          this.$refs.CustomerInfoCheckModal.setRowData(item, 'check')
        }
        return false
      }
      const _this = this
      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.FinanceCreditModal.setRowData(_this.selectedRows[0], 'check')
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'edit') {
        if (_this.selectedRows.length === 1) {
          const everyResult = this.selectedRows.every((item) => {
            return item.authStatus != 2
          })
          if (everyResult) {
            _this.$refs.FinanceCreditModal.setRowData(_this.selectedRows[0], 'edit')
            return
          } else {
            this.$message.warning('请选择未审核的额度')
          }
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'del') {
        if (_this.selectedRows.length === 1) {
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delLimitInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$message.success(res.message)
                _this.getData()
              } else {
                _this.$message.error(res.message)
              }
            },
            onCancel() {},
          })
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      switch (name) {
        case 'export':
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/customer/customer/financeReceipt/excel=${this.selectedRowKeys[0]}`
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤审警告',
            content: '确认要撤回此条记录的审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$message.success(res.message)
                _this.getData()
              } else {
                _this.$message.error(res.message)
              }
            },
            onCancel() {},
          })
          break
        case 'returnStatus':
          _this.$confirm({
            title: '返审警告',
            content: '确认要将此条记录返审至草稿状态吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await returnStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$message.success(res.message)
                _this.getData()
              } else {
                _this.$message.error(res.message)
              }
            },
            onCancel() {},
          })
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
